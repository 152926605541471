import React, { useRef, useState } from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SecondaryHeader from "../components/SecondaryHeader"
import { API_URL, RECAPTCHA_SITE_KEY } from "../utils/Constants"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"

const CareerPage = () => {
  const [loading, setLoading] = useState(false)
  const recaptchaRef = useRef(null)

  const [alert, setAlert] = useState({
    show: false,
    success: true,
    message: "",
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  })

  const onSubmit = data => {
    const recaptchaValue = recaptchaRef.current.getValue()

    if (!recaptchaValue) {
      setAlert({
        show: true,
        success: false,
        message: "Before you proceed, please complete the captcha.",
      })

      setTimeout(() => {
        setAlert({
          show: false,
          success: false,
          message: "",
        })
      }, 2000)
      return
    }

    setLoading(true)

    const formData = new FormData()
    formData.append("resume", data.resume[0])
    formData.append("first_name", data.first_name)
    formData.append("last_name", data.last_name)
    formData.append("email", data.email)
    formData.append("contact", data.contact)
    formData.append("message", data.message)

    fetch(`${API_URL}career`, {
      method: "POST",
      body: formData,
    })
      .then(res => res.json())
      .then(resData => {
        if (resData?.errors) {
          setAlert({
            show: true,
            success: false,
            message: "Something went wrong while sending resume.",
          })
          return
        }

        reset()
        setAlert({
          show: true,
          success: true,
          message: "Your request has been submitted.",
        })
      })
      .catch(e => {
        setAlert({
          show: true,
          success: false,
          message: "Something went wrong while sending resume.",
        })
      })
      .finally(() => {
        recaptchaRef.current.reset()
        setLoading(false)
        setTimeout(() => {
          setAlert({
            show: false,
            success: true,
            message: "",
          })
        }, 3500)
      })
  }

  const commonStyleForAlert = () => {
    return { padding: "2px 4px", width: "100%", color: "#fff" }
  }

  return (
    <Layout>
      <Seo title={`Career`} />
      <SecondaryHeader title={`Join Our Team!`} />

      <div id="career-page">
        <section className="main-section">
          <div className="pb-4" />

          <div className="main-section__text">
            <p>
              We are a fast-growing company seeking talented Treatment Planners,
              QC Managers and Orthodontists to join our team. If you are a
              highly motivated individual with a passion for Orthodontics and
              the right skillset, we want to hear from you.
            </p>

            <p>
              To apply for one of our open positions, please fill out the form
              below providing a summary of your relevant experience, attach your
              resume and submit.
            </p>
          </div>
          <div className="main-section__form-wrapper">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="main-section__form"
            >
              <div className="row">
                <input
                  className="input first_name"
                  type={"text"}
                  {...register("first_name")}
                  placeholder="First Name"
                  // required
                />
                <input
                  className="input last_name"
                  type={"text"}
                  {...register("last_name")}
                  placeholder="Last Name"
                  required
                />
              </div>
              <div className="row">
                <input
                  className="input email"
                  {...register("email")}
                  type={"email"}
                  placeholder="Email"
                  required
                />

                <input
                  className="input phone"
                  {...register("contact")}
                  type={"phone"}
                  placeholder="Contact"
                  required
                />
              </div>
              <div className="row">
                <textarea
                  {...register("message")}
                  className="input message"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <div className="flex flex-col	mb-4 md:flex-row">
                <div className="label">Attach Your Resume</div>
                <input
                  className="input"
                  type={"file"}
                  {...register("resume")}
                  required
                />
              </div>

              {alert.show && (
                <div className="row">
                  <div
                    style={
                      alert.success
                        ? {
                            ...commonStyleForAlert(),
                            background: "#198754",
                          }
                        : {
                            ...commonStyleForAlert(),
                            background: "#dc3545",
                          }
                    }
                  >
                    {alert.message}
                  </div>
                </div>
              )}

              <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} />

              <div className="pb-4" />

              <button type="submit" className="contact__section__form__btn">
                {loading ? <>Loading...</> : <>Submit</>}
              </button>
            </form>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default CareerPage
